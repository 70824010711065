'use strict';

(function() {
  function FindingResource($resource) {
    return $resource('api/v1/findings/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      moveFinding: {
        method: 'PUT',
        params: {
          id: '@_id',
          controller: 'move'
        }
      },
      getFindingsForPrint: {
        method: 'GET',
        params: {
          id: 'print'
        }
      },
      deleteImage: {
        method: 'DELETE',
        params: {
          controller: 'image'
        }
      },
      addImage: {
        method: 'PATCH',
        params: {
          controller: 'image'
        }
      },
      exportFindingsForTurbine: {
        method: 'GET',
        responseType: "arraybuffer",
        params: {
          id: 'export'
        }
      },
      importFindingImage: {
        method: 'POST',
        params: {
          id: 'import',
          controller: 'finding-image'
        }
      },


      importFindings: {
        method: 'POST',
        params: {
          id: 'import',
          controller: 'findings'
        }
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('Finding', FindingResource);

})();
